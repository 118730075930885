import Cookies from 'js-cookie'

const TokenKey = 'access-token'

const vipLevelKey = "user-level"


const isSvipKey = "user-svip"


//token
export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

//level
export function getVipLevel() {
    return Cookies.get(vipLevelKey)
}

export function setVipLevel(Level_id) {
    return Cookies.set(vipLevelKey, Level_id)
}

export function removeVipLevel() {
    return Cookies.remove(vipLevelKey)
}

export function getIsSvip() {
    return Cookies.get(isSvipKey)
}

export function setIsSvip(svip) {
    return Cookies.set(isSvipKey, svip)
}