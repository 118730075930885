export const baseURL = "https://api.moegoat.com/api/"; // production

export const vipList = [{
        id: 0,
        level: "😊 注册用户",
        title:'注册用户',
        color: "#fab57a",
        icon: "mdi-account-multiple-check"
    },
    {
        id: 1,
        level: "⭐ 月付会员",
        title:'⭐ 订阅VIP',
        color: "#f06868",
        img: "https://static.moegoat.com/images/vip/v1.png",
        icon: "mdi-star-circle"
    },
    {
        id: 2,
        level: "🌈 季付会员",
        title:'🔮 水晶VIP',
        color: "#aa96da",
        img: "https://static.moegoat.com/images/vip/v2.png",
        icon: "mdi-looks"
    },
    {
        id: 3,
        level: "🚀 年付会员",
        title:'💎 钻石VIP',
        color: "#07a2e3",
        img: "https://static.moegoat.com/images/vip/v3.png",
        icon: "mdi-diamond-stone"

    },
    {
        id: 4,
        level: "👑 永久会员",
        title:'👑 国王VIP',
        color: "#2a2a33",
        img: "https://static.moegoat.com/images/vip/v4.png",
        icon: "mdi-crown"
    }
]


// 微信支付二维码
// export const wxPay_qrcode = [
//     "https://static.moegoat.com/images/wxPay_qrcode/monthly.png",
//     "https://static.moegoat.com/images/wxPay_qrcode/quarterly.png",
//     "https://static.moegoat.com/images/wxPay_qrcode/annual.png",
//     "https://static.moegoat.com/images/wxPay_qrcode/lifelong.png"
// ]