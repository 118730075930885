export const blackWords = [
    // 广告类
    'V',
    'v',
    'q',
    'Q',
    '+',
    'wei',
    'wxin',
    'Wxin',
    'wXIN',
    '微',
    'line',
    'LINE',
    '赌',
    '威',
    '群',
    'ip',
    '加Q',
    'QQ',
    'qq',
    'Qq',
    '+Q',
    'vpn',
    'v信',
    'VX',
    'Vx',
    'vx',
    'WX',
    'wx',
    'wX',
    'Wx',
    '加微',
    '加V',
    '微x',
    '微xin',
    '维xin',
    '维x',
    '威xin',
    '威x',
    'jiaV',
    'jiav',
    '＋V',
    '＋v',
    'WEIxin',
    'weixin',
    'we1xin',
    'we1XIN',
    '微新',
    '加扣',
    '扣扣',
    'q群',
    'Q群',
    '扣群',
    'QQ群',
    '加群',
    'app',
    'a片',
    '姿势',
    '微信',
    '详情',
    'vxin',
    '老子',
    '加我',
    '代理',
    '兼职',
    '招聘',
    '网络',
    '彩票',
    '赌博',
    '贷款',
    '约会',
    '交易',
    '交友',
    '约炮',
    '同城',
    '约爱',
    '月我',
    '强贱',
    '卵轮',
    '偷拍',
    '网址',
    '网站',
    '棋牌',
    '人妻',
    '欲女',
    '可约',
    '可月',
    '可曰',
    '广告',
    '组图',
    '援交',
    '贷款',
    '出售',
    '购买',
    '头像',
    '看我',
    '有意者',
    '新司机',
    '约炮网',
    '接广告',
    '信息资源',
    '帮忙点下',
    '请点击进入',
    '请进入',
    '信用卡',
    '点一下',
    '私人服务器',
    '免费使用',
    '视频分享',
    '高清偷拍',
    '免费索取',
    '高清在线',
    '全集在线',
    '在线播放',
    '广告代理',
    '优质资源',
    '专业代理',
    '网络工作',
    //谩骂类
    'sb',
    'SB',
    '垃',
    '圾',
    '废',
    '操',
    '死',
    '草',
    '曹',
    '曰',
    '抄',
    'LJ',
    'lj',
    'nmsl',
    '假冒',
    '垃圾',
    '弱智',
    '废物',
    '操你',
    '草你',
    '干你',
    '盗版',
    '抄袭',
    '傻狗',
    '你妈',
    '死了',
    '全家',
    '去死',
    '骗子',
    '日你',
    '我擦',
    '弱智',
    '白痴',
    '奸你',
    '猥琐',
    '亵渎',
    '山寨',
    '猥褻',
    '吗逼',
    '妈B',
    '骗子',
    '辣鸡',
    'laji',
    'LAJI',
    'TMD',
    'MDZZ',
    '就这样',
    '好无聊',
    '垃圾吧',
    '垃圾把',
    '不值得',
    '和你妈',
    '骗人的',
    '干你吗',
    '干你妈',
    '草你吗',
    '草你妈',
    '草泥马',
    '操你妈',
    '神经病',
    '操你吗',
    '尼玛的',
    '你妈的',
    '砍死你',
    '操逼样',
    '好垃圾',
    '插你吗B',
    '真的垃圾',
    '什么垃圾',
    '辣鸡网址',
    '垃圾网站',
    '骗子网站',
    '你妈死了',
    '尼玛思乐',

    //网址类
    '网',
    'www',
    'Www',
    'wWw',
    'WWw',
    'WWW',
    'wwW',
    'WwW',
    'COm',
    'C0m',
    'COM',
    'cOm',
    'com',
    'cc',
    'net',
    'xyz',
    '.vip',
    'info',
    'pw',
    'tp',
    'top',
    'http',
    '点com',
    '点COM',
    '点Com',
    '点top',
    '点net',
    '点cn',
    '点xyz',
    '点vip',
    '点cc',
    '点tv',
    '点TV',
    '//',
    '.𝗖𝗢𝗠',
    '𝗖𝗢𝗠',
    '⽹址',
    '同诚',
    '囯',
    '全国',
    '約泡',
    '附近',
    '约炮',
    '免费',
    '约炮',
    '浏览器',
    '搜索',
    '網址',
    '全囯',
    '同誠',
    '女炮友',
    '⽹址'
]