<template>
  <v-dialog v-model="visible" width="900">
    <v-card :height="$vuetify.breakpoint.smAndUp ? '556' : ''">
      <v-container fluid class="pa-0" style="height:100%">
        <v-row class="flex-row fill-height" no-gutters>
          <!-- Left-Module -->
          <v-col class="d-none d-sm-flex" cols="6" style="height:100%">
            <v-img :src="loiimg" height="100%">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
              <v-row align="end" justify="center" class="fill-height flex-row" v-show="$vuetify.breakpoint.mdAndUp">
                <div class="mask">
                  <div class="mask-title pb-2">
                    <span class="titleblock"></span>
                    <span class="body-2 font-weight-bold">登入并享受</span>
                    <span class="titleblock"></span>
                  </div>

                  <v-row
                      class="mask-welfare justify-space-between flex-row mt-4 mx-3"
                      align="center"
                      justify="center"
                  >
                    <v-col class="mask-welfare-item" cols="3">
                      <v-btn fab dark small color="#ff62a5">
                        <v-icon dark>mdi-view-dashboard</v-icon>
                      </v-btn>
                      <div class="welfare-tip">海量资源</div>
                    </v-col>
                    <v-col class="mask-welfare-item" cols="3">
                      <v-btn fab dark small color="#3490de">
                        <v-icon dark>mdi-star</v-icon>
                      </v-btn>
                      <div class="welfare-tip">独家精选</div>
                    </v-col>
                    <v-col class="mask-welfare-item" cols="3">
                      <v-btn fab dark small color="#6b76ff">
                        <v-icon dark>mdi-fire</v-icon>
                      </v-btn>
                      <div class="welfare-tip">热门推荐</div>
                    </v-col>
                    <v-col class="mask-welfare-item" cols="3">
                      <v-btn fab dark small color="#556fb5">
                        <v-icon dark>mdi-update</v-icon>
                      </v-btn>
                      <div class="welfare-tip">每日更新</div>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </v-img>
            <v-divider vertical></v-divider>
          </v-col>

          <!-- Right-Module -->
          <v-col class="d-flex" sm="6" cols="12" align-self="center">
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="10">
                  <transition name="fade" mode="out-in">
                    <!-- Login - start -->
                    <div v-if="signType === 'in'" key="signIn">
                      <div
                          class="text-center headline mr-6"
                          :class="{'mb-11':$vuetify.breakpoint.smAndUp,'mb-6 mt-6':$vuetify.breakpoint.xsOnly}"
                          style="transform:translateY(-5px)"
                      >🚌 LOIBUS</div>
                      <!--  pb-5 -->
                      <h6 class="title mb-5" v-show="$vuetify.breakpoint.smAndUp">
                        <div class="form-line"></div>登录
                      </h6>
                      <v-form
                          ref="signInForm"
                          v-model="signInDataValid"
                          lazy-validation
                          class="mt-2"
                      >
                        <v-text-field
                            class="mb-3"
                            v-model="signInData.email"
                            :rules="emailRules"
                            label="电子邮件地址"
                            required
                            validate-on-blur
                            autocomplete="email"
                            autofocus
                            @keyup.enter="handleLogin"
                        ></v-text-field>
                        <v-text-field
                            class="mb-4"
                            v-model="signInData.password"
                            label="密码"
                            type="password"
                            :rules="passwordRules"
                            required
                            autocomplete="password"
                            @keyup.enter="handleLogin"
                        >
                          <template v-slot:append>
                            <a class="caption mt-1" @click="forgotPasswordDialog = true">忘记密码?</a>
                          </template>
                        </v-text-field>
                        <v-row class="justify-space-between">
                          <v-btn
                              text
                              class="light-blue--text text--darken-1 mt-4"
                              @click="signType = 'up'"
                          >创建账号</v-btn>
                          <v-btn
                              class="mt-4 white--text"
                              color="primary"
                              :loading="loading_signIn"
                              :disabled="loading_signIn"
                              @click="handleLogin"
                          >登录</v-btn>
                        </v-row>
                      </v-form>
                    </div>
                    <!-- Login - end -->
                    <!-- register - start -->
                    <div v-else key="signUp">
                      <h6 class="title mb-5" :class="{'mt-6':$vuetify.breakpoint.xsOnly}">
                        <div class="form-line"></div>注册 LOIBUS
                      </h6>
                      <v-form
                          ref="signUpForm"
                          v-model="signUpDataValid"
                          lazy-validation
                          class="mt-2"
                      >
                        <v-text-field
                            :autofocus="$vuetify.breakpoint.smAndUp"
                            class="mb-3"
                            v-model="signUpData.username"
                            :rules="[v => !!v || '请输入用户名!']"
                            label="用户名"
                            required
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            class="mb-3"
                            v-model="signUpData.email"
                            :rules="emailRules"
                            label="电子邮件地址"
                            required
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            class="mb-4"
                            v-model="signUpData.password"
                            label="密码"
                            :rules="passwordRules"
                            validate-on-blur
                            :append-icon="show_signUp_password ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show_signUp_password ? 'text' : 'password'"
                            @click:append="show_signUp_password = !show_signUp_password"
                            @keyup.enter="handleSignUp"
                            required
                            counter="20"
                            clearable
                        ></v-text-field>
                        <v-checkbox
                            v-model="agreement"
                            :rules="[v => !!v || '您必须年满18周岁才可继续注册']"
                            label="我已年满18周岁"
                            required
                            class="mt-2"
                            color="primary"
                        ></v-checkbox>
                        <v-row class="justify-space-between">
                          <v-btn
                              text
                              class="light-blue--text text--darken-1 mt-4"
                              @click="signType = 'in'"
                          >已有账号</v-btn>
                          <v-btn
                              :width="$vuetify.breakpoint.xsOnly? '90px' : ''"
                              class="mt-4 white--text"
                              color="primary"
                              :loading="loading_signUp"
                              :disabled="loading_signUp"
                              @click="handleSignUp"
                          >注册</v-btn>
                        </v-row>
                      </v-form>
                    </div>
                    <!-- register - end -->
                  </transition>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- 忘记密码 -->
    <v-dialog v-model="forgotPasswordDialog" width="400" persistent>
      <v-card class="px-3 pt-3 pb-2">
        <v-card-title class="subheading pb-2 font-weight-bold">重置密码</v-card-title>
        <v-card-text>输入您的电子邮件地址，我们会向您的邮箱发送一个重置密码链接。</v-card-text>
        <v-form ref="resetEmailForm" v-model="resetEmailDataValid" @submit.prevent lazy-validation>
          <v-text-field
              class="mx-6"
              v-model="resetEmail"
              :rules="emailRules"
              label="电子邮件地址"
              required
              validate-on-blur
              @keyup.enter="handleSendResetLink"
          ></v-text-field>
          <v-card-actions class="mt-5 mb-2 px-5">
            <v-btn
                color="primary"
                width="90"
                rounded
                text
                @click="forgotPasswordDialog = false"
            >返回登陆</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                width="130"
                class="white--text"
                color="primary"
                :loading="loading_reset"
                :disabled="reset_disabled || loading_reset"
                rounded
                @click.stop="handleSendResetLink"
            >{{reset_text}}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { sendResetLink } from "@/api/user.js";
import { filterSensitiveWord } from "@/utils/sensitive-word-filter/index.js";
import msg from "@/utils/snackbar.js";

export default {
  name: "AccountDialog",
  data: () => ({
    visible: false,
    signType: "in",
    forgotPasswordDialog: false,
    agreement: false,
    loading_signUp: false,
    loading_signIn: false,
    loading_reset: false,
    show_signUp_password: false,
    signInDataValid: true,
    signUpDataValid: true,

    timer_sendResetLink: null,
    timer_sendResetLink_count: 60,
    reset_disabled: false,
    reset_text: "发送验证邮件",
    resetEmailDataValid: true,
    resetEmail: "",
    signInData: {
      email: "",
      password: "",
    },
    signUpData: {
      username: "",
      email: "",
      password: "",
    },
    emailRules: [
      (v) => !!v || "请输入电子邮箱地址",
      (v) =>
          /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/.test(v) ||
          "请输入有效的电子邮件地址",
    ],
    passwordRules: [
      (v) => !!v || "请输入密码",
      (v) => (v && v.length >= 8 && v.length <= 20) || "密码长度为8-20个字符",
      (v) =>
          /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/.test(
              v
          ) || "密码必须包含字母、数字、符号种至少2种",
    ],
  }),
  methods: {
    handleLogin() {
      if (!this.$refs.signInForm.validate()) {
        return msg("请完整填写登录信息!", "error", "information");
      }
      this.loading_signIn = true;
      this.$store
          .dispatch("login", this.signInData)
          .then(() => {
            this.$store.dispatch("getInfo").then(() => {
              return msg(
                  "欢迎回来，" + this.$store.getters.user_name,
                  "success",
                  "emoticon-outline",
                  false
              );
            });
            // 清空登陆表单
            this.$refs.signInForm.reset();
            this.visible = false;
            this.loading_signIn = false;
          })
          .catch((error) => {
            msg(error.err_msg, "error", "information");
            this.loading_signIn = false;
          });
    },
    handleSignUp() {
      if (!this.$refs.signUpForm.validate()) {
        return msg("请完整填写注册信息!", "error", "information");
      }
      this.loading_signUp = true;

      //用户名敏感词校验
      let { flag } = filterSensitiveWord(this.signUpData.username);
      if (flag) {
        this.loading_signUp = false;
        return msg("用户名包含非法词汇", "error", "alert-circle");
      }

      this.$store
          .dispatch("signUp", this.signUpData)
          .then(() => {
            msg("注册成功", "success", "check");
            // 清空注册表单
            this.$refs.signUpForm.reset();
            // 取消同意复选框
            this.agreement = false;
            // 切换至登陆
            this.signType = "in";
            this.loading_signUp = false;
          })
          .catch((error) => {
            msg(error.err_msg, "error", "alert-circle");
            this.loading_signUp = false;
          });
    },
    async handleSendResetLink() {
      if (!this.$refs.resetEmailForm.validate()) {
        return msg("请正确填写需找回密码的邮箱!", "error", "information");
      }
      this.loading_reset = true;
      try {
        let resetEmailForm = {
          email: this.resetEmail,
        };
        let response = await sendResetLink(resetEmailForm);

        msg(response, "success", "check");

        //发送后禁用
        this.reset_disabled = true;
        this.timer_sendResetLink_count = 60;
        this.reset_text = `${this.timer_sendResetLink_count}秒后发送`;

        // 限制发送间隔
        this.timer_sendResetLink = setInterval(() => {
          this.timer_sendResetLink_count--;
          this.reset_text = `${this.timer_sendResetLink_count}秒后发送`;
          if (this.timer_sendResetLink_count <= 0) {
            clearInterval(this.timer_sendResetLink);
            this.timer_sendResetLink = null;
            this.reset_disabled = false;
            this.reset_text = "发送验证邮件";
          }
        }, 1000);

        this.forgotPasswordDialog = false;
      } catch (error) {
        if (error.includes("429")) {
          this.loading_reset = false;
          return msg("操作过于频繁啦，请过一会再试", "error", "information");
        }
        msg("请求异常", "error", "alert-circle");
      }
      this.loading_reset = false;
    },
  },
  computed: {
    ...mapState(["showAccountDialog"]),
    loiimg() {
      // return LoginViewImgList[
      //   Math.floor(Math.random() * (LoginViewImgList.length - 1))
      // ];
      return `https://static.moegoat.com/images/login/L-${
          Math.floor(Math.random() * 12) + 1
      }.jpg`;
    },
  },
  watch: {
    showAccountDialog(val) {
      this.visible = val;
    },
    visible(val) {
      if (val) {
        this.$store.commit("showAccountDialog");
      } else {
        this.$store.commit("hideAccountDialog");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.form-line {
  width: 20px;
  height: 3px;
  background-color: #039be5;
  border-radius: 2px;
  margin-bottom: 6px;
}

.mask {
  width: 100%;
  height: 275px;
  text-align: center;
  background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.8)
  );

  .mask-title {
    margin-top: 102px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: 1px;
    color: #ffffff;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      height: 2px;
      background-color: #eee;
      opacity: 0.35;
      width: 109px;
      position: relative;
      bottom: 5px;

      &::before {
        margin-right: 5px;
      }
      &::after {
        margin-left: 5px;
      }
    }

    .titleblock {
      display: inline-block;
      width: 4.2px;
      height: 4.2px;
      transform: rotate(45deg);
      background: #eeeeee;
      margin: 0 6px;
      position: relative;
      bottom: 4px;
    }
  }

  .mask-welfare {
    .welfare-tip {
      font-weight: 500;
      margin-top: 10px;
      font-size: 13px;
      color: #eeeeee;
    }
  }
}
</style>