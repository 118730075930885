import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import InfiniteLoading from "vue-infinite-loading"
import VueClipboard from 'vue-clipboard2'
import { handleSource } from './utils/ad-source'

import './permission' // permission control

import './style/style.css'


VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)


Vue.use(InfiniteLoading, {
    props: {
        spinner: "default"
    },
    system: {
        throttleLimit: 50
    }
});



handleSource();

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#Loi')