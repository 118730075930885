<template>
  <div>
    <!-- 导航 -->
    <v-app-bar
      class="navbar"
      :flat="
        !scrollTop && this.$route.name !== 'loi' && this.$route.name !== 'order'
      "
      :class="{
        scroll:
          scrollTop ||
          this.$route.name === 'loi' ||
          this.$route.name === 'order',
      }"
      :app="this.$route.name === 'loi' || this.$route.name === 'order'"
      clipped-left
      fixed
    >
      <v-app-bar-nav-icon
        class="hidden-sm-and-up white--text"
        @click="$store.dispatch('switchDrawer')"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        @click="$router.push('/')"
        class="white--text"
        style="cursor: pointer"
        >LOIBUS</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div class="nav-menu d-none d-sm-flex">
        <v-btn
          text
          class="nav-link mr-2"
          v-for="navLink in navLinks"
          :key="navLink.title"
          :to="navLink.to"
          active-class="active"
          rounded
          dark
          height="40"
          v-cloak
        >
          <v-icon class="nav-link-icon" v-text="navLink.icon"></v-icon>
          {{ navLink.title }}
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <!-- 开通会员按钮 -->
      <v-tooltip bottom color="white">
        <template v-slot:activator="{ on }">
          <v-scale-transition>
            <v-btn
              small
              text
              dark
              class="mr-3 mb-1 title"
              fab
              v-on="on"
              to="/vip"
              v-show="$store.getters.token && $store.getters.vip_id == 0"
              >👑</v-btn
            >
          </v-scale-transition>
        </template>
        <span class="grey--text text--darken-4 font-weight-light"
          >开通高级会员</span
        >
      </v-tooltip>

      <!-- 搜索按钮 -->
      <v-tooltip bottom color="white">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="searchDialog = true" class="mr-2" dark>
            <v-icon v-text="'mdi-magnify'"></v-icon>
          </v-btn>
        </template>
        <span class="grey--text text--darken-4 font-weight-light"
          >找到你的乐趣</span
        >
      </v-tooltip>

      <!-- 通知菜单 -->
      <v-menu offset-y transition="slide-y-transition" left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="getNotificationList" class="mr-2" dark>
            <v-icon v-text="'mdi-bell'"></v-icon>
          </v-btn>
        </template>
        <v-card width="400">
          <v-list v-if="notification_list.length >= 1">
            <v-list-item
              v-for="(notification, index) in notification_list"
              :key="index"
              @click="
                showNotificationDialog(notification.title, notification.content)
              "
            >
              <v-list-item-icon>
                <v-icon v-text="'mdi-message'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="notification.title"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text class="body-1">{{
                  notification.date
                }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-row justify="center" align="center" style="height: 100px" v-else>
            <v-progress-circular
              indeterminate
              color="grey"
              :size="30"
              :width="3"
            ></v-progress-circular>
          </v-row>
        </v-card>
      </v-menu>
      <!-- 通知菜单 -->

      <!-- 已登录用户菜单 -->
      <template v-if="$store.getters.token">
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              :class="{
                'mr-0': $vuetify.breakpoint.xsOnly,
                'mr-4': $vuetify.breakpoint.smAndUp,
              }"
              @click="getUserInfo"
              dark
            >
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>

          <!-- usermenu -->
          <v-card width="200px">
            <v-list>
              <v-list-item-group v-model="model">
                <v-list-item v-if="!username">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="dark"
                      :size="20"
                      :width="2"
                    ></v-progress-circular>
                  </v-row>
                </v-list-item>
                <v-list-item to="/account" v-else>
                  <v-list-item-icon>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="transform: translateY(-6px)"
                    >{{username}}</v-list-item-title>
                    <v-list-item-subtitle
                    >{{vip.substring(2)}}{{svip === 1? '[巴士会]' : ''}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item
                  v-for="item in accountItems"
                  :key="item.title"
                  :to="item.to"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- 发布地址 -->
                <v-hover v-slot:default="{ hover }">
                  <v-list-item
                    href="https://www.loix.cc"
                    target="_blank"
                    :style="{ background: hover ? '#fb7299' : '' }"
                  >
                    <v-list-item-icon>
                      <v-icon :dark="hover">mdi-link</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title :class="{ 'white--text': hover }"
                        >发布地址</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-hover>

                <!-- 退出登录 -->
                <v-list-item @click="handleLogout">
                  <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium"
                      >退出登录</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>

          <!-- usermenu - end -->
        </v-menu>
      </template>
      <template v-else>
        <v-btn @click="$store.commit('showAccountDialog')" text dark outlined>
          <v-icon dark class="mr-2">mdi-account-circle</v-icon>登录
        </v-btn>
      </template>
    </v-app-bar>

    <!-- 通知弹窗 -->
    <v-dialog v-model="notificationDialog" width="400" persistent>
      <v-card class="px-3 pt-1 pb-2">
        <v-card-title class="subheading pb-2 font-weight-bold">{{
          notificationTitle
        }}</v-card-title>
        <v-card-text
          class="mb-2 mt-6"
          v-html="notificationContent"
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="notificationDialog = false"
            >我已阅读</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 搜索弹窗 -->
    <v-dialog
      v-model="searchDialog"
      :width="$vuetify.breakpoint.xsOnly ? '90%' : '600'"
      content-class="searchBar"
    >
      <v-text-field
          solo
          hide-details
          autofocus
          placeholder="找到你的乐趣"
          prepend-inner-icon="mdi-magnify"
          color="loi"
          clearable
          @keyup.enter.native="searchEnterHandler"
          v-model="searchInput"
      ></v-text-field>
    </v-dialog>

    <!-- 用户注册&登录 -->
    <account-dialog />
  </div>
</template>

<script>
import AccountDialog from "@/components/AccountDialog.vue";
import { vipList } from "@/config.js";
import { fetch_notification_list } from "@/api/notification.js";
import msg from "@/utils/snackbar.js"; //消息提示组件

export default {
  name: "Navbar",
  components: {
    AccountDialog,
  },
  data: () => ({
    drawer: true,
    searchDialog: false,
    isLoading: false,
    searchItems: [],
    model: null,
    search: null,
    searchInput: "",

    accountDialog: false,

    notificationDialog: false,
    notificationTitle: "",
    notificationContent: "",

    username: "",
    vip: "",
    svip:0,

    scrollTop: 0,

    navLinks: [
      {
        icon: "mdi-lightbulb-on-outline",
        title: "发现",
        to: "/",
      },
      {
        icon: "mdi-fire",
        title: "热榜",
        to: "/popular",
      },
      {
        icon: "mdi-tag-heart",
        title: "分类",
        to: "/library?tab=1",
      },
    ],
    accountItems: [
      {
        icon: "mdi-square-inc-cash",
        text: "高级会员",
        to: "/vip",
      },
      {
        icon: "mdi-account-box",
        text: "个人资料",
        to: "/account",
      },
      {
        icon: "mdi-heart",
        text: "我的喜欢",
        to: "/My_Likes",
      },
      {
        icon: "mdi-clock",
        text: "下载记录",
        to: "/My_downloadRecord",
      },
      {
        icon: "mdi-face-agent",
        text: "帮助中心",
        to: "/support",
      },
    ],
    notification_list: [],
  }),
  methods: {
    showNotificationDialog(title, content) {
      this.notificationTitle = title;
      this.notificationContent = content;
      this.notificationDialog = true;
    },
    hideAccountDialog() {
      this.accountDialog = false;
    },
    searchEnterHandler(e) {
      let keyCode = window.event ? e.keyCode : e.which;

      if (keyCode == 13 && this.searchInput) {
        this.$router.push({
          name: "search",
          query: { keyword: this.searchInput },
        });
        this.searchInput = "";
        this.searchDialog = false;
      }
    },
    scrollToTop() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    handleLogout() {
      this.$store.dispatch("logout").then(() => {
        msg("登出成功", "primary", "information");

        this.$router.push("/");
      });
    },
    getUserInfo() {
      this.$store.dispatch("getInfo").then((data) => {
        this.username = data.username;
        this.vip = vipList[data.vip_id].level;
        this.svip = data.svip;
      });
    },
    async getNotificationList() {
      try {
        let response = await fetch_notification_list();
        this.notification_list = response.data;
        // window.console.log(this.notification_list);
      } catch (error) {
        msg("获取通知失败", "error", "alert-circle", false);
      }
    },
  },
  mounted() {
    // 监视页面滚动
    window.addEventListener("scroll", this.scrollToTop);
  },
};
</script>
<style lang="scss">
.searchBar {
  position: absolute;
  top: 11vh;
}

.navbar {
  background-color: transparent !important;
  color: white;
  transition: all 0.4s ease-in-out;

  &.scroll {
    background-color: #212121 !important;
  }

  .nav-menu {
    position: absolute;
    left: 50%;
    transform: translate(-46%, 0);

    .nav-link {
      color: #bdbdbd !important;

      .nav-link-icon {
        padding-right: 5px;
      }

      &:hover {
        color: #ffffff !important;
      }

      &.active {
        color: #ffffff !important;
      }
    }
  }
}
</style>