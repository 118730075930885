import Vue from 'vue';
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#03A9F4', //colors.lightBlue,
                dark: '#212121',
                like: '#ED4956',
                bpink: "#fb7299"
            },
            dark: {

            }
        }

    }
});