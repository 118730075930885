import request from '@/utils/request'

export function oldUserCheck(data) {
    return request({
        url: 'user/oldUserCheck',
        method: 'post',
        data
    })
}

export function signup(data) {
    return request({
        url: 'user/signup',
        method: 'post',
        data
    })
}



export function login(data) {
    return request({
        url: 'user/login',
        method: 'post',
        data
    })
}

export function getInfo(token) {
    return request({
        url: 'user/info',
        method: 'get',
        params: { token }
    })
}

export function logout() {
    return request({
        url: 'user/logout',
        method: 'post'
    })
}

export function resetPassword(data) {
    return request({
        url: 'user/password/reset',
        method: 'post',
        data
    })
}

export function changePassword(data) {
    return request({
        url: 'user/password/change',
        method: 'post',
        data
    })
}


export function send_verifyLink(token) {
    return request({
        url: 'user/email/resend',
        method: 'get',
        headers: { Authorization: token }
    })
}

export function sendResetLink(data) {
    return request({
        url: 'user/password/email',
        method: 'post',
        data
    })
}

export function verify(email, id, signature, expires) {
    return request({
        url: `user/email/verify/${id}/${email}?expires=${expires}&signature=${signature}`,
        method: 'get'
    })
}

export function getUserSubscribed() {
    return request({
        url: 'user/subscribed/list',
        method: 'get'
    })
}

export function clear_downloadHistory() {
    return request({
        url: 'user/history/clear',
        method: 'get'
    })
}

export function delete_downloadHistory(data) {
    return request({
        url: 'user/history/delete',
        method: 'post',
        data
    })
}