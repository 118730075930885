export function handleSource(){
    const queryString = window.location.search;
    if (queryString){
        const urlParams = new URLSearchParams(queryString);
        //console.log("urlParams：" + urlParams);
        //utm_source=acgUmi&utm_medium=icon&utm_campaign=acgUmi

        const utm_source = urlParams.get('utm_source')
        // const utm_medium = urlParams.get('utm_medium')
        // const utm_campaign = urlParams.get('utm_campaign')


        if (utm_source){
            localStorage.setItem('ad_source',utm_source)
            // console.log(utm_source);
        }

        // if (utm_medium){
        //     localStorage.setItem('ad_medium',utm_medium)
        //     console.log(utm_medium);
        // }
        //
        // if (utm_campaign){
        //     localStorage.setItem('ad_campaign',utm_campaign)
        //     console.log(utm_campaign);
        // }
    }

}

