<template>
  <v-app>
    <v-navigation-drawer v-model="$store.state.drawer" app disable-resize-watcher>
      <v-list>
        <v-list-item v-for="navLink in navLinks" :key="navLink.title" :to="navLink.to">
          <v-list-item-action>
            <v-icon v-text="navLink.icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="navLink.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <Navbar />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>

      <!-- 全局提示 -->
      <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarType">
        <v-icon
          class="mr-2"
          v-if="snackbarIcon"
          style="transform:translateY(-2px)"
        >mdi-{{snackbarIcon}}</v-icon>
        <span class="subtitle-1">{{ snackbarText }}</span>
        <template v-slot:action="{ attrs }" v-if="snackbarCloseBtn">
          <v-btn fab text v-bind="attrs" @click="snackbar = false" small>
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <!--灯箱-->
      <CoolLightBox
          :closeOnClickOutsideMobile="true"
          :items="imgItems"
          :index="imgIndex"
          :fullScreen="true"
          slideshowColorBar="#fb7299"
          @close="closeLightBox"
      >
      </CoolLightBox>

    </v-main>
    <v-footer dark class="pa-3">
      <v-spacer></v-spacer>
      <div class="caption">LOIBUS &copy; {{ new Date().getFullYear() }} Made with ❤️ in Amsterdam.</div>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapGetters } from "vuex";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'


export default {
  name: "Layout",
  components: {
    Navbar,
    CoolLightBox
  },
  data() {
    return {
      navLinks: [
        {
          icon: "mdi-lightbulb-on-outline",
          title: "发现",
          to: "/",
        },
        {
          icon: "mdi-fire",
          title: "热榜",
          to: "/popular",
        },
        {
          icon: "mdi-tag-heart",
          title: "分类",
          to: "/library?tab=1",
        },
      ],
    };
  },
  methods:{
    closeLightBox() {
      this.$store.commit('setImgIndex', null);
    }
  },
  computed: {
    ...mapGetters([
      "snackbarText",
      "snackbarType",
      "snackbarIcon",
      "snackbarCloseBtn",
      "imgItems",
      "imgIndex"
    ]),
    snackbar: {
      get() {
        return this.$store.getters.snackbar;
      },
      set() {
        this.$store.dispatch("hideSnackbar");
      },
    }
  }
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: linear;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
