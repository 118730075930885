import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import { signup, login, logout, getInfo } from '@/api/user'
import {getToken, setToken, removeToken, getVipLevel, setVipLevel, removeVipLevel, setIsSvip} from '@/utils/auth'
import { baseURL } from '@/config.js'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user_id: null,
        vip_id: getVipLevel(),
        user_name: '',
        user_email: '',
        expiry_date: '',
        user_status: 0,
        email_verified: null,
        token: getToken(),
        signUp_token: '',
        showAccountDialog: false,
        drawer: false,
        snackbar: {
            show: false,
            type: '',
            text: '',
            icon: '',
            close: false
        },
        
        imgItems: [],
        imgIndex: null
    },
    mutations: {
        SET_USER_ID: (state, user_id) => {
            state.user_id = user_id
        },
        SET_VIP_ID: (state, vip_id) => {
            state.vip_id = vip_id
        },
        SET_USER_NAME: (state, user_name) => {
            state.user_name = user_name
        },
        SET_USER_EMAIL: (state, user_email) => {
            state.user_email = user_email
        },
        SET_EXPIRY_DATE: (state, expiry_date) => {
            state.expiry_date = expiry_date
        },
        SET_EMAIL_VERIFIED: (state, email_verified) => {
            state.email_verified = email_verified
        },
        SET_USER_STATUS: (state, user_status) => {
            state.user_status = user_status
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_SIGNUP_TOKEN: (state, token) => {
            state.signUp_token = token
        },
        // 用户刷新 token 成功，使用新的 token 替换掉本地的token
        refreshToken: (state, token) => {
            state.token = token
            setToken(token)
            axios.defaults.headers.common['Authorization'] = state.token
        },
        showAccountDialog: (state) => {
            state.showAccountDialog = true;
        },
        hideAccountDialog: (state) => {
            state.showAccountDialog = false;
        },
        switchDrawer: (state) => {
            state.drawer = !state.drawer
        },
        showSnackbar: (state, payload) => {
            state.snackbar.type = payload.type
            state.snackbar.text = payload.text;
            state.snackbar.icon = payload.icon;
            state.snackbar.close = payload.close;
            state.snackbar.show = true;
        },
        hideSnackbar: (state) => {
            state.snackbar.show = false;
        },
        
        // 设置图片数组
        setImgItems(state, items) {
            state.imgItems = items;
        },
        // 设置图片索引
        setImgIndex(state, index) {
            state.imgIndex = index;
        }

    },
    actions: {
        // user signUp
        signUp({ commit }, userInfo) {
            const { username, email, password } = userInfo

            const ad_source = localStorage.getItem('ad_source');

            return new Promise((resolve, reject) => {
                signup({ username: username.trim(), email: email.trim(), password: password,ad_source:ad_source }).then(response => {
                    const signup_token = response.signup_token
                    // 发送验证邮件
                    axios.get(baseURL + 'user/email/resend', {
                            headers: { Authorization: signup_token }
                        })
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error)
                        })
                    commit('SET_SIGNUP_TOKEN', signup_token)
                    resolve()
                }).catch(error => {
                    if (error.response) {
                        reject(error.response.data.message)
                    }
                    reject(error)
                })
            })
        },

        // user login
        login({ commit }, userInfo) {
            const { email, password } = userInfo
            return new Promise((resolve, reject) => {
                login({ email: email.trim(), password: password }).then(response => {
                    const access_token = response.access_token
                    commit('SET_TOKEN', access_token)
                    setToken(access_token)
                    setVipLevel(response.vip_level)
                    setIsSvip(response.svip)
                    resolve()
                }).catch(error => {
                    if (error.response) {
                        reject(error.response.data.message)
                    }
                    reject(error)
                })
            })
        },

        // get user info
        getInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo(state.token).then(response => {
                    const { data } = response

                    if (!data) {
                        reject('Verification failed, please Login again.')
                    }
                    setVipLevel(data.vip_id)
                    setIsSvip(data.svip)
                    commit('SET_USER_ID', data.id)
                    commit('SET_VIP_ID', data.vip_id)
                    commit('SET_USER_NAME', data.username)
                    commit('SET_USER_EMAIL', data.email)
                    commit('SET_EXPIRY_DATE', data.expiry_at)
                    commit('SET_EMAIL_VERIFIED', data.email_verified)
                    commit('SET_USER_STATUS', data.status)
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
        },


        // user logout
        logout({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_USER_ID', 0)
                    commit('SET_VIP_ID', 0)
                    commit('SET_USER_NAME', '')
                    commit('SET_USER_EMAIL', '')
                    commit('SET_EXPIRY_DATE', '')
                    commit('SET_EMAIL_VERIFIED', null)
                    commit('SET_USER_STATUS', -2)
                    commit('SET_SIGNUP_TOKEN', '')
                    removeToken()
                    removeVipLevel()
                    resolve()
                }).catch(error => {
                    commit('SET_TOKEN', '')
                    commit('SET_USER_ID', 0)
                    commit('SET_VIP_ID', 0)
                    commit('SET_USER_NAME', '')
                    commit('SET_USER_EMAIL', '')
                    commit('SET_EXPIRY_DATE', '')
                    commit('SET_EMAIL_VERIFIED', null)
                    commit('SET_USER_STATUS', -2)
                    commit('SET_SIGNUP_TOKEN', '')
                    removeToken()
                    removeVipLevel()
                    resolve()
                    reject(error)
                })
            })
        },

        // remove token
        resetToken({ commit }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                commit('SET_USER_ID', 0)
                removeToken()
                removeVipLevel()
                resolve()
            })
        },

        // 将刷新的 token 保存至本地
        refreshToken({ commit }, token) {
            return new Promise((resolve) => {
                commit('refreshToken', token)
                resolve()
            })
        },


        //登录模态
        showAccountDialog: ({ commit }) => {
            commit("showAccountDialog");
        },
        hideAccountDialog: ({ commit }) => {
            commit("hideAccountDialog");
        },

        switchDrawer: ({ commit }) => {
            commit('switchDrawer')
        },

        // 显示全局提示
        showSnackbar: ({ commit }, options) => {
            commit('showSnackbar', {
                type: options.type,
                text: options.text,
                icon: options.icon,
                close: options.close
            })
        },
        // 隐藏全局提示
        hideSnackbar: ({ commit }) => {
            commit('hideSnackbar')
        },
        
        // 异步更新图片数组
        updateImgItems({ commit }, items) {
            commit('setImgItems', items);
        },
        // 异步更新图片索引
        updateImgIndex({ commit }, index) {
            commit('setImgIndex', index);
        }

    },
    getters: {
        token: state => state.token,
        user_id: state => state.user_id,
        vip_id: state => state.vip_id,
        user_name: state => state.user_name,
        user_email: state => state.user_email,
        expiry_date: state => state.expiry_date,
        email_verified: state => state.email_verified,
        user_status: state => state.user_status,

        // 全局提示
        snackbar: state => state.snackbar.show,
        snackbarText: state => state.snackbar.text,
        snackbarType: state => state.snackbar.type,
        snackbarIcon: state => state.snackbar.icon,
        snackbarCloseBtn: state => state.snackbar.close,
        
  
        // 获取图片列表
        imgItems: state => state.imgItems,
        // 获取当前图片索引
        imgIndex: state => state.imgIndex,
    }
})