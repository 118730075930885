import router from "./router";
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";


const blackList = ['/account', '/my_likes', '/my_downloadRecord', '/order']; // redirect blackList

router.beforeEach(async (to, from, next) => {

    // set page title

    document.title = getPageTitle(to.meta.title);


    // determine whether the user has logged in
    const hasToken = getToken();

    if (hasToken) {
        next()
    } else {
        /* has no token*/
        if (blackList.includes(to.path)) {
            next(`/`)
        } else {
            next()
        }
    }
});