import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from '@/layout'
import Layout_blank from '@/layout/blank'

export default new Router({
    mode: "history",
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: "/",
            component: Layout,
            children: [{
                path: "/",
                component: () => import('@/views/discover/index'),
                name: "discover"
            }],
        },
        {
            path: "/source/:source",
            component: Layout_blank,
            children: [{
                path: "/",
                component: () => import('@/views/ad-source/index'),
                name: "ad-source"
            }],
        },
        // {
        //     path: "/",
        //     component: Layout,
        //     children: [{
        //         path: "/",
        //         name: "order",
        //         component: () => import('@/views/order/index'),
        //         meta: { title: '订单支付' }
        //     }],
        // },
        {
            path: "/popular",
            component: Layout,
            children: [{
                path: "/",
                name: "popular",
                component: () => import('@/views/popular/index'),
                meta: {title: '热门库'}
            }],
        },
        {
            path: "/library",
            component: Layout,
            children: [{
                path: "/",
                name: "library",
                component: () => import('@/views/categories/index'),
                meta: {title: '分类库'}
            }],
        },
        {
            path: "/vip",
            component: Layout,
            children: [{
                path: "/",
                name: "vip",
                component: () => import('@/views/vip/index'),
                meta: {title: '高级会员'}
            }],
        },
        {
            path: "/support",
            component: Layout,
            children: [{
                path: "/",
                name: "support",
                component: () => import('@/views/help-center/index'),
                meta: {title: '帮助中心'}
            }],
        },
        {
            path: "/order",
            component: Layout,
            children: [{
                path: "/",
                name: "order",
                component: () => import('@/views/order/index'),
                meta: {title: '订单支付'}
            }],
        },
        {
            path: "/account",
            component: Layout,
            children: [{
                path: "/",
                name: "account",
                component: () => import('@/views/account/index'),
                meta: {title: '我的资料'}

            }],
        },
        {
            path: "/my_likes",
            component: Layout,
            children: [{
                path: "/",
                name: "my_likes",
                component: () => import('@/views/my-likes/index'),
                meta: {title: '我的喜欢'}
            }],
        },
        {
            path: "/my_downloadrecord",
            component: Layout,
            children: [{
                path: "/",
                name: "my_downloadrecord",
                component: () => import('@/views/my-downloadhistory/index'),
                meta: {title: '下载记录'}
            }],
        },
        {
            path: "",
            component: Layout,
            children: [{
                path: "/search",
                name: "search",
                component: () => import('@/views/search/index'),
                meta: {title: '搜索'}
            }],
        },
        {
            path: "/lois/:id",
            component: Layout,
            children: [{
                path: "/",
                name: "loi",
                component: () => import('@/views/loi/index'),
                meta: {title: 'Loi'}
            }],
        },
        {
            path: "/reset",
            component: Layout_blank,
            children: [{
                path: "/",
                name: "ResetPasswordPage",
                component: () => import('@/views/resetpassword'),
                meta: {title: '密码重置'}
            }]
        },
        {
            path: "/verify",
            component: Layout_blank,
            children: [{
                path: "/",
                name: "VerifyEmailPage",
                component: () => import('@/views/verifyemail'),
                meta: {title: '账号验证'}
            }]
        },
        {
            path: "",
            component: Layout,
            children: [{
                path: "/library/loier/:id",
                name: "category-page",
                component: () => import('@/views/category-page'),
                meta: {title: '人物库'}
            }]
        },
        {
            path: "",
            component: Layout,
            children: [{
                path: "/library/tag/:id",
                name: "tag-page",
                component: () => import('@/views/tag-page'),
                meta: {title: '标签库'}
            }]
        }
    ]
});