import axios from "axios";
import store from "@/store.js";
import { getToken } from "@/utils/auth";
import { baseURL } from '@/config.js'

// create an axios instance
const service = axios.create({
    baseURL: baseURL, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 200000 // request timeout
});

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            // config.headers["X-Token"] = getToken();
            config.headers.Authorization = getToken();
        }
        return config;
    },
    error => {
        // do something with request error
        // window.console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    response => {
        let token = response.headers.authorization

        //  header 中存在 token，则触发 refreshToken 方法，替换本地的 token
        if (token) {
            store.dispatch('refreshToken', token)
        }

        const res = response.data;

        // if the custom code is not 20000, it is judged as an error.
        if (res.status == 'fail') {
            // if (res.err_code == -2) {
            //     return window.alert(res.err_msg)
            // }
            return Promise.reject(res || '未知错误')
        } else {
            return res
        }
    },
    error => {
        // 如果响应中的 http code 为 401，则此用户 token 失效，触发 logout 方法，清除本地的数据并将用户重定向至登录页面
        if (error.response.status === 401) {
            store.dispatch("logout").then(() => {
                window.alert('登录已过期，请重新登陆');
                store.dispatch("showSnackbar", {
                    type: 'error',
                    text: "登陆已过期"
                });
                this.$router.push("/");
            });

        }

        //操作频繁
        if (error.response.status === 429) {

            // console.log(error.response.data.message);

            return Promise.reject({
                err_msg:error.response.data.message + ", 请等会再试~"
            });

            // store.dispatch("showSnackbar", {
            //     icon:'information',
            //     type: 'error',
            //     text: error.response.data.message
            // });
        }


        // window.console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);

export default service;